var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.footerShow
    ? _c("div", { staticClass: "fixed-footer" }, [
        _c("div", { staticClass: "weui-tab" }, [
          _c(
            "div",
            { staticClass: "weui-tabbar" },
            [
              _c(
                "router-link",
                {
                  staticClass: "weui-tabbar__item",
                  attrs: { to: { name: "home" } }
                },
                [
                  _c("span", { staticClass: "weui-tabbar__icon" }, [
                    _c("i", { staticClass: "iconfont icon-home" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "weui-tabbar__label" }, [
                    _vm._v(_vm._s(_vm.Tran_home))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "weui-tabbar__item",
                  attrs: { to: { name: "topic" } }
                },
                [
                  _c("span", { staticClass: "weui-tabbar__icon" }, [
                    _c("i", { staticClass: "iconfont icon-topic" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "weui-tabbar__label" }, [
                    _vm._v(_vm._s(_vm.Tran_topic))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "weui-tabbar__item",
                  attrs: { to: { name: "post" } }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "weui-tabbar__icon",
                      staticStyle: { position: "relative" }
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-fabu",
                        staticStyle: {
                          position: "absolute",
                          top: "-22px",
                          left: "-4px",
                          "font-size": "35px"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "weui-tabbar__label" }, [
                    _vm._v(_vm._s(_vm.Tran_post))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "weui-tabbar__item",
                  attrs: { to: { name: "special" } }
                },
                [
                  _c("span", { staticClass: "weui-tabbar__icon" }, [
                    _c("i", { staticClass: "iconfont icon-tags" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "weui-tabbar__label" }, [
                    _vm._v(_vm._s(_vm.Tran_tags))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "weui-tabbar__item",
                  attrs: { to: { name: "member" } }
                },
                [
                  _c("span", { staticClass: "weui-tabbar__icon" }, [
                    _c("i", { staticClass: "iconfont icon-member" })
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "weui-tabbar__label" }, [
                    _vm._v(_vm._s(_vm.Tran_member))
                  ])
                ]
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5dcd034e", { render: render, staticRenderFns: staticRenderFns })
  }
}