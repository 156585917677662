import {WPBlogSiteUrl, apiUrl} from "./api.js";
import AllPageLanguage from "./language.js";
import as from "axios";
import weuijs from "weui.js";
import {isPc} from './config'

exports.install = function (Vue, options) {
  Vue.prototype.formatTime = formatTime;
  Vue.prototype.formatTTime = formatTTime;
  Vue.prototype.APLang = AllPageLanguage;
  Vue.prototype.siteConfig = siteConfig;
  Vue.prototype.replaceImgUrl = replaceImgUrl;
  Vue.prototype.weui = weuijs;
  Vue.prototype.replaceFeaturesImg = replaceFeaturesImg;
  Vue.prototype.replaceBannerImg = replaceBannerImg;
  Vue.prototype.replaceAvatarImg = replaceAvatarImg;
  Vue.prototype.replaceTopicImg = replaceTopicImg;
  Vue.prototype.responseError = responseError;
  Vue.prototype.checkLogin = checkLogin;
  Vue.prototype.checkToken = checkToken;
  Vue.prototype.replaceSearchTitle = replaceSearchTitle;
  Vue.prototype.checkIsLogin = checkIsLogin
};

/*----------------------------------------
-------------  format  time  -------------
----------------------------------------*/
function formatTime(val){
  var date = new Date(val);
  let result = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  return result;
}

/*----------------------------------------
---------  format time in china  ---------
----------------------------------------*/
function formatTTime(val) {
  // ...
  let resule = new Date(val).toLocaleString().replace(/[年月]/g,'-').replace(/[日上下午]/g,'');
  return resule;
}

/*----------------------------------------
----  Add website URL prefix for url  ----
----------------------------------------*/
function replaceImgUrl(strs){
  let hzreg = /((href|src)="\/)/g; // wordpress media path !!!
  var st = strs.replace(hzreg,function(a){
    if (a === 'href="/') {
      return 'href="' + WPBlogSiteUrl + '/';
    }else if(a === 'src="/') {
      return 'src="' + WPBlogSiteUrl + '/';
    }
  });
  return st;
}

/*----------------------------------------
---------  replace Features Img  ---------
----------------------------------------*/
function replaceFeaturesImg(data, type) {
  let newImgData = []
  let  defaultIMG = 'static/images/logo.png';
  if (type === 'post') {
    defaultIMG = 'static/images/post.png';
  } else if (type === 'expert') {
    defaultIMG = 'static/images/expert.png';
  }
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      if (data[i]._embedded["wp:featuredmedia"] !== undefined) {
        if (data[i]._embedded["wp:featuredmedia"][0].code === "rest_post_invalid_id") {
          data[i].featured_media = defaultIMG;
        }else{
          data[i].featured_media = data[i]._embedded["wp:featuredmedia"][0].source_url;
        }
      }else{
        data[i].featured_media = defaultIMG;
      }
      newImgData.push(data[i]);
    }
  }

  return newImgData;
}


/*----------------------------------------
---------  replace Banner Img  ---------
----------------------------------------*/
function replaceBannerImg(data) {
  let newImgData = [],
    defaultIMG = 'static/images/logo.png';
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      // if (data[i]._embedded["wp:featuredmedia"] !== undefined) {
      //   if (data[i]._embedded["wp:featuredmedia"][0].code === "rest_post_invalid_id") {
      //     data[i].featured_media = defaultIMG;
      //   }else{
      //     data[i].featured_media = data[i]._embedded["wp:featuredmedia"][0].source_url;
      //   }
      // }else{
      //   data[i].featured_media = defaultIMG;
      // }
      if (!data[i]['source_url']) {
        data[i]['source_url'] = defaultIMG
      }
      newImgData.push(data[i]);
    }
  }

  return newImgData;
}


/*----------------------------------------
---------  replace Banner Img  ---------
----------------------------------------*/
function replaceAvatarImg(data) {
  let newImgData = [],
    defaultIMG = 'static/images/logo.png';
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      if (data[i].user !== undefined) {
        if (!data[i].user["avatar_url"]) {
          data[i].user["avatar_url"] = defaultIMG;
        }
      }else{
        if (!data[i]["avatar_url"]) {
          data[i]["avatar_url"] = defaultIMG;
        }
      }
      newImgData.push(data[i]);
    }
  }

  return newImgData;
}

/*----------------------------------------
---------  replace topic Img  ---------
----------------------------------------*/


function replaceTopicImg(data) {
  let newImgData = [],
    defaultIMG = 'static/images/topic.png';
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      if (data[i].tag !== undefined) {
        if (!data[i].tag["img_url"]) {
          data[i].tag["img_url"] = defaultIMG;
        }
      }else{
        if (!data[i]["img_url"]) {
          data[i]["img_url"] = defaultIMG;
        }
      }
      newImgData.push(data[i]);
    }
  }

  return newImgData;
}


/*----------------------------------------
----------  Response detection  ----------
----------------------------------------*/
function responseError(err, type, _this) {
  let self = this;
  if (err.response.statusText === "Unauthorized" || err.response.data.code === "rest_not_logged_in" || err.response.data.code === "rest_comment_login_required") {
    if (type === 'article') {
      if (isPc) {
        _this.$store.dispatch('setIsShowLogin', true)
      } else {
        self.$router.push("/login");
      }      
    } else { 
      self.weui.confirm(err.response.data.message,{
        title: self.APLang.noneLogin,
        buttons: [{
          label: self.APLang.popAction.cancel,
          type: 'default',
          onClick: function(){
            // self.$router.push("/");
            if (self.$route.name === 'member') {
              self.$router.go(-1)
            }
          }
        }, {
          label: self.APLang.popAction.ok,
          type: 'primary',
          onClick: function(){
            if (isPc) {
              _this.$store.dispatch('setIsShowLogin', true)
            } else {
              self.$router.push("/login");
            }   
            // sessionStorage.removeItem("userToken");
            // self.weui.toast(self.APLang.popAction.success, 3000);
          }
        }]
      });
      return false;
    }
  }
  if(err.response) {
    if (err.response.status !== 200) {
      self.weui.topTips(err.response.data.message,3000);
    }
  }else{
    self.weui.topTips(self.APLang.unknownMistake,3000);
  }
}

/*----------------------------------------
--------------  check Token  -------------
----------------------------------------*/
function checkToken(self) {
  let userToken = sessionStorage.getItem("userToken") || null;
  if (userToken !== null) {
    userToken = 'Bearer ' + userToken;
  }else{
    self.checkLogin();
  }
  let apiUrl = as.create({
    baseURL: WPBlogSiteUrl + '/wp-json/wp/v2/',
    headers: {
      // 'content-type': 'application/x-www-form-urlencoded',
      'content-type': 'application/json',
      'Authorization': userToken
    }
  });
}

/*----------------------------------------
--------------  check login  -------------
----------------------------------------*/
function checkLogin() {
  let self = this;
  if (sessionStorage.getItem("userToken")) {
    self.weui.confirm(self.APLang.msg.logged,{
      buttons: [{
        label: self.APLang.popAction.cancel,
        type: 'default',
        onClick: function(){
          self.$router.push("/");
        }
      }, {
        label: self.APLang.popAction.ok,
        type: 'primary',
        onClick: function(){
          sessionStorage.removeItem("userToken");
          self.weui.toast(self.APLang.popAction.success, 3000);
        }
      }]
    });
    return false;
  }
}

/*----------------------------------------
-------------  site configs  -------------
----------------------------------------*/
function siteConfig(callback) {
  apiUrl.get("/getSiteInfo").then(res => {
    let result = {
      name: res.data.siteName,
      description: res.data.description,
      home: res.data.home,
      url: res.data.url
    };
    typeof callback === 'function' && callback.call(window,result);
    return result;
  });
}


/*----------------------------------------
-------------  replace search title to change color for keyword  -------------
----------------------------------------*/

function replaceSearchTitle(title, keyword) {
  let reg = new RegExp(keyword,"gi");
  // console.log(reg)
  return title.replace(reg, '<strong class="key">'+ keyword +'</strong>')
}

function checkIsLogin() {
  let userToken = sessionStorage.getItem("userToken")
  return userToken ? true : false
}
