import Vue from 'vue';
import Router from 'vue-router';

import {isPc} from './config'

Vue.use(Router);

export default new Router({
  linkActiveClass: "weui-bar__item_on",
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/home.vue'], resolve);
        }
      }
    },
    {
      path: '/articles',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/articles.vue'], resolve);
        }
      }
    },
    {
      path: '/article/:id',
      component(resolve) {
        if (isPc) {
          // require(['../pc/home.vue'], resolve);
          require(['../mobile/article.vue'], resolve);
        } else {
          require(['../mobile/article.vue'], resolve);
        }
      }
    },
    {
      path: '/topic',
      name: 'topic',
      component(resolve) {
        if (isPc) {
          // require(['../pc/home.vue'], resolve);
          require(['../pc/moreColumn.vue'], resolve);
        } else {
          require(['../mobile/pages.vue'], resolve);
        }
      }
    },
    {
      path: '/column/:id',
      name: 'column',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/column.vue'], resolve);
        }
      }
    },
    {
      path: '/topic/:slug',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/page.vue'], resolve);
        }
      }
    },
    {
      path: '/post',
      name: 'post',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/post.vue'], resolve);
        }
      }
    },
    {
      path: '/tags',
      name: 'tags',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/tags.vue'], resolve);
        }
      }
    },
    {
      path: '/tag/:id',
      component(resolve) {
        if (isPc) {
          require(['../pc/tag.vue'], resolve);
        } else {
          require(['../mobile/tag.vue'], resolve);
        }
      }
    },
    {
      path: '/login',
      component(resolve) {
        if (isPc) {
          // require(['../pc/home.vue'], resolve);
          require(['../mobile/oauth/login.vue'], resolve);
        } else {
          require(['../mobile/oauth/login.vue'], resolve);
        }
      }
    },
    {
      path: '/register',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/oauth/register.vue'], resolve);
        }
      }
    },
    {
      path: '/member',
      name: 'member',
      component(resolve) {
        if (isPc) {
          // require(['../pc/home.vue'], resolve);
          require(['../mobile/member.vue'], resolve);
        } else {
          require(['../mobile/member.vue'], resolve);
        }
      }
    },
    {
      path: '/media',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/media.vue'], resolve);
        }
      }
    },
    {
      path: '/comments',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/comments.vue'], resolve);
        }
      }
    },
    {
      path: '/search',
      name: 'search',
      component(resolve) {
        if (isPc) {
          require(['../pc/search.vue'], resolve);
        } else {
          require(['../mobile/search.vue'], resolve);
        }
      }
    },
    {
      path: '/specialColumn',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/specialColumn.vue'], resolve);
        }
      }
    },
    {
      path: '/favorite',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/favorite.vue'], resolve);
        }
      }
    },
    {
      path: '/favoriteList',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/favoriteList.vue'], resolve);
        }
      }
    },
    {
      path: '/special',
      name: 'special',
      component(resolve) {
        if (isPc) {
          require(['../pc/special.vue'], resolve);
        } else {
          require(['../mobile/special.vue'], resolve);
        }
      }
    },
    {
      path: '/myTags',
      name: 'myTags',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/myTags.vue'], resolve);
        }
      }
    },
    {
      path: '/corpus',
      name: 'corpus',
      component(resolve) {
        if (isPc) {
          // require(['../pc/home.vue'], resolve);
        } else {
          require(['../pc/myZone/myCorpus.vue'], resolve);
        }
      }
    },
    {
      path: '/setting',
      name: 'setting',
      component(resolve) {
        if (isPc) {
          require(['../pc/setting.vue'], resolve);
        } else {
          require(['../mobile/setting.vue'], resolve);
        }
      }
    },
    {
      path: '/forgetPsd',
      name: 'forgetPsd',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/oauth/forgetPsd.vue'], resolve);
        }
      }
    },
    {
      path: '/changePwd',
      name: 'changePwd',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/changePwd.vue'], resolve);
        }
      }
    },
    {
      path: '/photos',
      name: 'photos',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/photos.vue'], resolve);
        }
      }
    },
    {
      path: '/folllowee',
      name: 'folllowee',
      component(resolve) {
        if (isPc) {
          require(['../pc/home.vue'], resolve);
        } else {
          require(['../mobile/folllowee.vue'], resolve);
        }
      }
    },
    {
      path: '/onesColumn/:id',
      name: 'onesColumn',
      component(resolve) {
        if (isPc) {
          require(['../pc/onesColumn.vue'], resolve);
        } else {
          require(['../mobile/onesColumn.vue'], resolve);
        }
      },
      children: [
        {
          path: '/onesColumn/:id',
          name: 'ones',
          component(resolve) {
            if (isPc) {
              require(['../pc/ones.vue'], resolve);
            }
          }
        },
        {
          path: '/onesColumn/:id/fans',
          name: 'fans',
          component(resolve) {
            if (isPc) {
              require(['../pc/fans.vue'], resolve);
            }
          }
        },
        {
          path: '/onesColumn/:id/follow',
          name: 'follow',
          component(resolve) {
            if (isPc) {
              require(['../pc/follow.vue'], resolve);
            }
          }
        }
      ]
    },
    {
      path: '/about',
      name: 'about',
      component(resolve) {
        if (isPc) {
          // require(['../pc/home.vue'], resolve);
          require(['../mobile/about.vue'], resolve);
        } else {
          require(['../mobile/about.vue'], resolve);
        }
      }
    },
    {
      path: '/siteSetting',
      name: 'siteSetting',
      component(resolve) {
        if (isPc) {
          require(['../pc/siteSetting.vue'], resolve);
        } else {
          require(['../mobile/siteSetting.vue'], resolve);
        }
      },
      children: [
        {
          path: '/siteSetting',
          name: 'basicSetting',
          component(resolve) {
            if (isPc) {
              require(['../pc/setting/basicSetting.vue'], resolve);
            }
          },
        },
        {
          path: '/photoSetting',
          name: 'photoSetting',
          component(resolve) {
            if (isPc) {
              require(['../pc/setting/photoSetting.vue'], resolve);
            }
          },
        },
        {
          path: '/bannerSetting',
          name: 'bannerSetting',
          component(resolve) {
            if (isPc) {
              require(['../pc/setting/bannerSetting.vue'], resolve);
            }
          },
        },
        {
          path: '/qcCodeSetting',
          name: 'qcCodeSetting',
          component(resolve) {
            if (isPc) {
              require(['../pc/setting/qcCodeSetting.vue'], resolve);
            }
          },
        }
      ]
    },
    {
      path: '/categoriesSetting',
      name: 'categoriesSetting',
      component(resolve) {
        if (isPc) {
          require(['../pc/categoriesSetting.vue'], resolve);
        } else {
          require(['../mobile/categoriesSetting.vue'], resolve);
        }
      }
    },
    {
      path: '/pcArticleList',
      name: 'pcArticleList',
      component(resolve) {
        if (isPc) {
          require(['../pc/pcArticleList.vue'], resolve);
        }
      }
    },
    {
      path: '/pcChildArticleList',
      name: 'pcChildArticleList',
      component(resolve) {
        if (isPc) {
          require(['../pc/pcChildArticleList.vue'], resolve);
        }
      }
    },
    {
      path: '/pcArticle',
      name: 'pcArticle',
      component(resolve) {
        if (isPc) {
          require(['../pc/pcArticle.vue'], resolve);
        }
      }
    },
    {
      path: '/wechatAuthorization',
      name: 'wechatAuthorization',
      component(resolve) {
        if (isPc) {
          require(['../pc/wechatAuthorization.vue'], resolve);
        } else {
          require(['../mobile/wechatAuthorization.vue'], resolve);
        }
      }
    },
    {
      path: '/write',
      name: 'write',
      component(resolve) {
        if (isPc) {
          require(['../pc/write.vue'], resolve);
        }
      },
      children: [
        {
          path: '/write',
          name: 'writeEditor',
          component(resolve) {
            if (isPc) {
              require(['../pc/writeEditor.vue'], resolve);
            }
          },  
        },
        {
          path: '/writeList',
          name: 'writeList',
          component(resolve) {
            if (isPc) {
              require(['../pc/writeList.vue'], resolve);
            }
          },  
        },
        {
          path: '/writeCorpus',
          name: 'writeCorpus',
          component(resolve) {
            if (isPc) {
              require(['../pc/writeCorpus.vue'], resolve);
            }
          },  
        },
        {
          path: '/writeEditor',
          name: 'writeEditor',
          component(resolve) {
            if (isPc) {
              require(['../pc/writeEditor.vue'], resolve);
            }
          },  
        },
        {
          path: '/writeTag',
          name: 'writeTag',
          component(resolve) {
            if (isPc) {
              require(['../pc/writeTag.vue'], resolve);
            }
          },  
        },
        {
          path: '/writeCollection',
          name: 'writeCollection',
          component(resolve) {
            if (isPc) {
              require(['../pc/writeCollection.vue'], resolve);
            }
          },  
        },
        {
          path: '/writePhoto',
          name: 'writePhoto',
          component(resolve) {
            if (isPc) {
              require(['../pc/writePhoto.vue'], resolve);
            }
          },  
        },
      ]
    },
    {
      path: '/myZone',
      name: 'myZone',
      component(resolve) {
        if (isPc) {
          require(['../pc/myZone/myZone.vue'], resolve);
        }
      },
      children: [
        {
          path: '/myZone/:id/fans',
          name: 'fans',
          component(resolve) {
            if (isPc) {
              require(['../pc/fans.vue'], resolve);
            }
          }
        },
        {
          path: '/myZone/:id/follow',
          name: 'follow',
          component(resolve) {
            if (isPc) {
              require(['../pc/follow.vue'], resolve);
            }
          }
        },
        {
          path: '/myZone',
          name: 'myEssay',
          component(resolve) {
            if (isPc) {
              require(['../pc/myZone/myEssay.vue'], resolve);
            }
          },
        },
        {
          path: '/myCorpus',
          name: 'myCorpus',
          component(resolve) {
            if (isPc) {
              require(['../pc/myZone/myCorpus.vue'], resolve);
            }
          },
        },
        {
          path: '/myTopic',
          name: 'myTopic',
          component(resolve) {
            if (isPc) {
              require(['../pc/myZone/myTopic.vue'], resolve);
            }
          },
        },
        {
          path: '/myCollection',
          name: 'myCollection',
          component(resolve) {
            if (isPc) {
              require(['../pc/myZone/myCollection.vue'], resolve);
            }
          },
        },
        {
          path: '/myPhotos',
          name: 'myPhotos',
          component(resolve) {
            if (isPc) {
              require(['../pc/myZone/myPhotos.vue'], resolve);
            }
          },
        },
        {
          path: '/myNote',
          name: 'myNote',
          component(resolve) {
            if (isPc) {
              require(['../pc/myZone/myNote.vue'], resolve);
            }
          },
        }
      ]
    }
    
  ]
});