<template>
  <div class="pc-fixed-header">
    <div class="pc-wrap">
      <div class="logo">
        <img @click="routerLinkToHome" src="static/images/logo-ape.png" alt="">
      </div>
      <!-- <div class="logo">{{ showPGTitle }}</div> -->
      <ul class="pc-fixed-menu cl">
        <li class="fl-l" @click="routerLinkToHome">首页</li>
        <li class="fl-l" v-for="(item, index) in categoriesData" :key="item.id" @mouseover="hoverIndex = index" @mouseout="hoverIndex = -1">
          <span>{{ item.name }}</span>
          <i class="iconfont icon-menu-down" :class="{ active: index === hoverIndex }"></i>
          <div class="pc-menu-box" v-show="hoverIndex === index">
            <div v-for="item in chilidCategoriesData[index]" :key="item.id" @click="routerLinkToArticle(item.id, item.name)">
              <img :src="item.meta._avatar_url_cat" alt="">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="pc-search-wrap">
        <input type="search" v-model="searchValue" class="input" placeholder="输入关键字搜索" @click="getHotSearchList" @keydown.enter="getSearch" />
        <i class="iconfont icon-search" @click="getSearch"></i>
        <div id="search-tips" class="search-tips" v-if="isSHowHotSearch">
          <div class="search-trending">
            <h5 class="search-trending-header cl">
              热门搜索
              <span @click="getNextPageHotSearchList">换一批</span>
            </h5>
            <ul class="search-trending-tag-wrap">
              <li v-for="item in hotSearchList" @click="getSearchByHot(item)">{{ item }}</li>
            </ul>
          </div>
          <div class="search-recent">
              <ul class="search-recent-item-wrap">
                <li v-for="(item, index) in historySearch" class="search-recent-item">
                  <div @click="getSearchByHot(item)"><i class="iconfont icon-history1"></i><span>{{ item }}</span></div>
                  <i @click="deleteHistorySearch(item)" class="iconfont icon-close history-close"></i>
                </li>
              </ul>
          </div>
        </div>
      </div>
      <div class="pc-fixed-header-user fl-r" v-if="!currentUserIsLogin">
        <button type="primary" class="weui-btn weui-btn_inline weui-btn_default" style="font-size: 14px;line-height: 1.5" @click="showLogin">立即登录</button>
        <button type="primary" class="weui-btn weui-btn_inline weui-btn_primary" style="font-size: 14px;line-height: 1.5" @click="showRegister">免费注册</button>
      </div>
      <div class="pc-fixed-header-user fl-r" v-else>
        <img class="pc-header-avatar" :src="userInfo.avatar" alt="" @mouseover="imgMouseOver" @mouseout="imgMouseOut">
        <div style="display: inline-block;width: 100px;
          height: 40px;
          line-height: 40px;
          margin: 8px 15px 0;
          border-radius: 20px;
          font-size: 15px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          background-color: #ea6f5a;" @click="routerLinkToWrite">写文章</div>
        <div class="pc-header-avatar-info" v-show="isShowHeaderInfo" @mouseover="imgMouseOver" @mouseout="imgMouseOut">
          <ul>
            <li @click="routerLinkToMyZone"><i class="iconfont icon-user2"></i> 我的空间</li>
            <li @click="routerLinkToMyWallet"><i class="iconfont icon-wallet"></i> 我的钱包</li>
            <li @click="routerLinkToMySetting"><i class="iconfont icon-settings2"></i> 设置</li>
            <li v-if="isAdmin" @click="routerLinkToCategoriesSetting"><i class="iconfont icon-settings2"></i> 目录设置</li>
            <li v-if="isAdmin" @click="routerLinkToSiteSetting"><i class="iconfont icon-settings2"></i> 站点管理</li>
            <li @click="logoutUser"><i class="iconfont icon-exit2"></i> 退出</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="weui-dialog_confirm" v-if="$store.state.isShowLogin">
      <div class="weui-mask" @click="hideLogin"></div>
      <div class="weui-dialog">
        <div class="weui-dialog__hd">
          <strong class="weui-dialog__title">登录</strong>
          <span class="fl-r weui-dialog__close" @click="hideLogin">x</span>
        </div>
        <div class="weui-dialog__bd ta-l">
          <form @submit.prevent="submitLogin" id="login">
          <div class="">
            <div class="cell">
              <div class="cell-head">
                <label class="weui-label">{{Tran_user}}</label>
              </div>
              <input class="cell-input" type="text" v-model="userData.username" :placeholder="Tran_username" autocomplete="username"/>
            </div>
            <div class="cell">
              <div class="cell-head"><label class="weui-label">{{Tran_pwd}}</label></div>
              <input class="cell-input" type="password" v-model="userData.password" :placeholder="Tran_password" autocomplete="current-password"/>
            </div>
            <div class="weui-cell weui-cell_switch">
              <div class="weui-cell__bd">{{Tran_autoLogin}}</div>
              <div class="weui-cell__ft">
                <input class="weui-switch" type="checkbox"/>
              </div>
            </div>
            <span class="weui-cells__tips" @click="showRegister">{{Tran_noneAccount}}</span>
            <span class="weui-cells__tips" @click="showForget">忘记密码？</span>
          </div>
          <div class="weui-btn-area">
            <button type="submit" class="weui-btn weui-btn_primary">{{Tran_login}}</button>
          </div>
          </form>
          <div class="more-login">
            <h6>社交帐号登录</h6>
            <div>
              <i class="iconfont icon-wechat" @click="toWechatLogin"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="weui-dialog_confirm" v-if="$store.state.isShowRegister">
      <div class="weui-mask" @click="hideRegister"></div>
      <div class="weui-dialog">
        <div class="weui-dialog__hd">
          <strong class="weui-dialog__title">注册</strong>
          <span class="fl-r weui-dialog__close" @click="hideRegister">x</span>
        </div>
        <div class="weui-dialog__bd ta-l">
          <form @submit.prevent="submitRegister" id="register">
            <div class="weui-cells weui-cells_form">
              <div class="weui-cell">
                <div class="weui-cell__hd"><label class="weui-label">{{Tran_user}}</label></div>
                <div class="weui-cell__bd">
                  <input class="weui-input" type="text" v-model="registerData.username" :placeholder="Tran_username"/>
                </div>
              </div>
              <div class="weui-cell">
                <div class="weui-cell__hd"><label class="weui-label">{{Tran_mail}}</label></div>
                <div class="weui-cell__bd">
                  <input class="weui-input" type="text" v-model="registerData.email" :placeholder="Tran_email" autocomplete="email"/>
                </div>
              </div>
              <span class="weui-cells__tips" @click="returnToLogin">{{Tran_haveAccount}}</span>
            </div>
            <div class="weui-btn-area">
              <button type="submit" class="weui-btn weui-btn_primary">{{Tran_register}}</button>
            </div>
          </form>
          <div class="more-login">
            <h6>社交帐号登录</h6>
            <div>
              <i class="iconfont icon-wechat" @click="toWechatLogin"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="weui-dialog_confirm" v-if="$store.state.isShowForget">
      <div class="weui-mask" @click="hideForget"></div>
      <div class="weui-dialog">
        <div class="weui-dialog__hd">
          <strong class="weui-dialog__title">忘记密码</strong>
          <span class="fl-r weui-dialog__close" @click="hideForget">x</span>
        </div>
        <div class="weui-dialog__bd ta-l">
          <form @submit.prevent="submitLostPassword" id="forget">
            <div class="weui-cells weui-cells_form">
              <div class="weui-cell">
                <div class="weui-cell__hd"><label class="weui-label">{{Tran_mail}}</label></div>
                <div class="weui-cell__bd">
                  <input class="weui-input" type="text" v-model="forgetData.email" :placeholder="Tran_email" autocomplete="email"/>
                </div>
              </div>
              <span class="weui-cells__tips" @click="returnToLogin">返回登录</span>
            </div>
            <div class="weui-btn-area">
              <button type="submit" class="weui-btn weui-btn_primary">找回密码</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { WPBlogSiteUrl, apiUrl } from "../../utils/api.js";  
  import axios from "axios";

  export default {
    data () {
      return {
        showPGTitle: '',
        categoriesData: [],
        chilidCategoriesData: [],
        hoverIndex: -1,

        searchValue: '',

        currentUserIsLogin: false,
        isShowHeaderInfo: false,
        isShowHeaderInfoTimer: null,
        isAdmin: false,

        isShowLogin: false,
        userData: [],
        userInfo: [],
        userToken: '',
        Tran_username: this.APLang.forms.username,
        Tran_password: this.APLang.forms.password,
        Tran_autoLogin: this.APLang.msg.autoLogin,
        Tran_noneAccount: this.APLang.msg.noneAccount,
        Tran_login: this.APLang.login,
        Tran_user: this.APLang.forms.form.username,
        Tran_pwd: this.APLang.forms.form.userpassword,

        userId: '',

        pages: {
          page_count: 0,
          page: 1,
          per_page: 10
        },
        hotSearchList: [],
        isSHowHotSearch: false,

        // register
        registerData: {},
        forgetData: {},
        Tran_register: this.APLang.register,
        Tran_mail: this.APLang.forms.form.useremail,
        Tran_email: this.APLang.forms.email,
        Tran_haveAccount: this.APLang.msg.haveAccount,

        isShowRegister: false,


        wechatUrl: '',
        historySearch: []
      }
    },
    mounted () {
      this.showPGConfig()
      this.getMenu() // 获取目录，目录下有第二级目录才展示
      if (this.checkIsLogin()) {
        this.getUserInfo()
      }
      this.getHistorySearch()
      // this.redirectToWechatLogin()
      document.onclick = (ev) => {
        if (ev.target.type !== 'search') {
          if (ev.target.offsetParent) {
            if (ev.target.offsetParent.id !== 'search-tips' &&  ev.target.offsetParent.className !== 'search-recent-item') {
              this.isSHowHotSearch = false
            }
          } else {
            this.isSHowHotSearch = false
          }
        }
      }
    },
    methods: {
      showPGConfig(){
        this.siteConfig(data=>{
          this.showPGTitle = data.name
          // this.$store.commit('newTitle', data.name);
        });// page title
        // this.$store.commit('showFooter', true);// footer if show
      },
      // get user info
      // get -> users/me
      getUserInfo(){
        apiUrl.get("users/me").then(res => {
          this.userInfo = {
            avatar: res.data.meta.avatar_url || res.data.avatar_urls['96'],
            name: res.data.name,
            folllowee: res.data.meta._to_followee
          }
          this.currentUserIsLogin = true
          // this.galleryId = res.data.meta.USER_GALLERY_ROOT
          this.isAdmin = res.data.meta.wp_user_level === '10' ? true : false
          this.userId = res.data.id
        }).catch(err => {
          this.currentUserIsLogin = false
          // this.responseError(err);
          this.weui.loading().hide();
        });
      },
      getMenu () {
        apiUrl.get("categories", {
          params: {
            parent: 0
          }
        }).then(res => {
          let length = res.data.length
          let count = 0
          let homeCategoriesData = []
          let categoriesData = []
          this.homeCategoriesData = res.data
          for (let i in res.data) {
            apiUrl.get('categories', {
              params: {
                parent: res.data[i].id,
                orderby: 'slug'
              }
            }).then(response => {
              count++
              if (response.data.length === 0) {
                // homeCategoriesData[i] = res.data[i]
              } else {
                categoriesData[i] = res.data[i]
                this.chilidCategoriesData[i] = this.replaceDefaultImg(response.data)
              }
              if (count === length) {
                for (let j = 0; j < categoriesData.length; j++) {
                  if (categoriesData[j]) {
                    this.categoriesData.push(categoriesData[j])
                  }
                  if (!this.chilidCategoriesData[j]) {
                    this.chilidCategoriesData.splice(j, 1)
                  }
                }
                // console.log(this.categoriesData)
                this.$store.dispatch('setCategoriesData', this.categoriesData)
              }
            })
          }
        });
      },
      getSearch () {
        this.$router.push({ name: 'search', query: { q: this.searchValue }})
      },
      getSearchByHot (item) {
        this.isSHowHotSearch = false
        this.$router.push({ name: 'search', query: { q: item }})
        let historySearch = localStorage.getItem('historySearch') || '[]'
        historySearch = JSON.parse(historySearch)
        for (let i = 0, length = historySearch.length; i < length; i++) {
          if (historySearch[i] === item) {
            return
          }
        }
        historySearch.unshift(item)
        if (historySearch.length > 5) {
          historySearch.length = 5
        }

        localStorage.setItem('historySearch', JSON.stringify(historySearch))
        this.historySearch = historySearch
      },
      deleteHistorySearch (item) {
        let historySearch = localStorage.getItem('historySearch') || '[]'
        historySearch = JSON.parse(historySearch)
        for (let i = 0, length = historySearch.length; i < length; i++) {
          if (historySearch[i] === item) {
            historySearch.splice(i, 1)
            break
          }
        }
        localStorage.setItem('historySearch', JSON.stringify(historySearch))
        this.historySearch = historySearch
      },
      getHistorySearch () {
        let historySearch = localStorage.getItem('historySearch') || '[]'
        this.historySearch = JSON.parse(historySearch)
      },
      replaceDefaultImg(data) {
        let newImgData = [],
          defaultIMG = 'static/images/category.png';
        for (const i in data) {
          if (data.hasOwnProperty(i)) {
            if (!data[i]['meta']["_avatar_url_cat"]) {
              data[i]['meta']["_avatar_url_cat"] = defaultIMG;
            }
            newImgData.push(data[i]);
          }
        }

        return newImgData;
      },

      submitLogin() {
        let self = this;

        if (!self.userData.username || !self.userData.password) {
          self.weui.topTips(self.APLang.msg.emptyInput,800);
          return false;
        }

        axios.post(WPBlogSiteUrl + "/wp-json/jwt-auth/v1/token",{
          username: self.userData.username,
          password: self.userData.password
        }).then(res => {
          if(res.data.token) {
            sessionStorage.setItem("userToken", res.data.token);
            window.location.reload();
          }
          // setTimeout(() => {
          //   window.location.reload();
          // }, 200)
          // self.weui.confirm(self.APLang.msg.loginSuccess,{
          //   buttons: [{
          //     label: self.APLang.popAction.return,
          //     type: 'primary',
          //     onClick: function(){
          //       self.$router.go(-1);
          //       // self.$router.push("/member");
          //       setTimeout(() => {
          //         window.location.reload();
          //       }, 200)
          //     }
          //   }]
          // });
        }).catch(err => {
          self.responseError(err);
          self.weui.loading().hide();
        });
      },
      imgMouseOver () {
        clearTimeout(this.isShowHeaderInfoTimer)
        this.isShowHeaderInfo = true
      },
      imgMouseOut () {
        this.isShowHeaderInfoTimer = setTimeout(() => {
          this.isShowHeaderInfo = false
        }, 300)
      },

      routerLinkToCategoriesSetting () {
        this.$router.push('/categoriesSetting')
      },
      routerLinkToHome () {
        this.$router.push('/home')
      },
      routerLinkToArticle (id, name) {
        this.$router.push( { name: 'pcArticle', query: { id: id, name: name }} )
      },
      routerLinkToMyZone () {
        this.$router.push('/myZone')
      },
      routerLinkToSiteSetting () {
        this.$router.push('/siteSetting')
      },
      routerLinkToMySetting () {
        this.$router.push('/setting')
      },
      routerLinkToMyWallet() {
        let self = this;
        self.weui.confirm('尚未开通该功能',{
          buttons: [{
            label: self.APLang.popAction.ok,
            type: 'primary',
            onClick: function(){
            }
          }]
        });
      },
      logoutUser() {
        let self = this;
        if(sessionStorage.getItem("userToken")) {
          self.weui.dialog({
            title: '',
            content: self.APLang.msg.logged,
            buttons: [{
              label: self.APLang.popAction.cancel,
              type: 'default',
              onClick: function () {
                //
              }
            }, {
              label: self.APLang.popAction.ok,
              type: 'primary',
              onClick: function () {
                sessionStorage.removeItem("userToken");
                self.weui.topTips(self.APLang.msg.logoutSuccess,{
                  duration: 600,
                  callback: function(){
                    window.location.reload();
                    self.$router.push("/");
                  }
                });
              }
            }]
          });
        }
      },
      routerLinkToWrite () {
        window.open(document.location.origin + '/#/write')
      },

      getHotSearchList () {
        apiUrl.get('getHotSearch', {
          params: {
            page: this.pages.page,
            per_page: this.pages.per_page
          }
        }).then(res => {
          this.hotSearchList = res.data
          this.isSHowHotSearch = true
          if (this.pages.page_count === 0) {
            this.pages.page_count = parseInt(res.headers['x-wp-totalpages']);
          }
        })
      },
      getNextPageHotSearchList () {
        if (this.pages.page === this.pages.page_count) {
          this.pages.page = 1
        } else {
          this.pages.page++
        }
        this.getHotSearchList()
      },
      toWechatLogin () {
        apiUrl.get('redirectToWechatLogin').then(res => {
          this.wechatUrl = res.data.url
        })
        setTimeout(() => {
          window.open(this.wechatUrl)
        }, 800)
      },
      redirectToWechatLogin () {
        
      },

      // register
      showRegister () {
        this.$store.dispatch('setIsShowLogin', false)
        this.weui.confirm('请确保邮箱正确，初始密码将发送到您的邮箱',{
          title: '注册提示',
          buttons: [{
            label: this.APLang.popAction.ok,
            type: 'primary',
            onClick: () => {
              this.$store.dispatch('setIsShowRegister', true)
            }
          }]
        });
      },

      hideRegister () {
        this.$store.dispatch('setIsShowRegister', false)
      },

      returnToLogin () {
        this.$store.dispatch('setIsShowRegister', false)
        this.$store.dispatch('setIsShowForget', false)
        this.$store.dispatch('setIsShowLogin', true)
      },

      showLogin () {
        this.$store.dispatch('setIsShowLogin', true)
        this.$store.dispatch('setIsShowRegister', false)
      },

      hideLogin () {
        this.$store.dispatch('setIsShowLogin', false)
      },

      showForget () {
        this.$store.dispatch('setIsShowLogin', false)
        this.$store.dispatch('setIsShowForget', true)
      },

      hideForget () {
        this.$store.dispatch('setIsShowForget', false)
      },

      submitRegister() {
        let self = this;

        if (!self.registerData.username || !self.registerData.email) {
          self.weui.topTips('用户名和邮箱不能为空',1500);
          return false;
        }

        if (!(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(self.registerData.email))) {
          self.weui.topTips('请输入正确的邮箱',1500);
          return false;
        }

        self.weui.loading('检查用户信息中...');
        apiUrl.post("register",{
          username: self.registerData.username,
          email: self.registerData.email
        }).then(res => {
          self.weui.loading().hide();
          if(res.data.msg !== '您提供的邮箱地址DNS不可达。') {
            self.weui.confirm(self.APLang.msg.loginThisAccount,{
              buttons: [{
                label: self.APLang.popAction.cancel,
                type: 'default',
                onClick: function(){
                  // self.$router.push("/");
                }
              }, {
                label: self.APLang.popAction.ok,
                type: 'primary',
                onClick: function(){
                  self.isShowRegister = false
                  self.isShowLogin = true
                }
              }]
            });
          } else {
            self.weui.confirm(res.data.msg,{
              buttons: [{
                label: self.APLang.popAction.ok,
                type: 'primary',
                onClick: function(){
                }
              }]
            });
          }
        }).catch(err => {
          self.weui.loading().hide();
          self.responseError(err);
        });
      },

      submitLostPassword() {
        let self = this;

        if (!self.forgetData.email) {
          self.weui.topTips('邮箱不能为空',1500);
          return false;
        }

        if (!(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(self.forgetData.email))) {
          self.weui.topTips('请输入正确的邮箱',1500);
          return false;
        }
        self.weui.loading(this.APLang.loading);
        apiUrl.post("lostPassword",{
          email: self.forgetData.email
        }).then(res => {
          self.weui.loading().hide();
          self.weui.confirm('新密码已经发送到您的邮箱，是否现在登录？',{
            buttons: [{
              label: self.APLang.popAction.cancel,
              type: 'default',
              onClick: function(){
                // self.$router.push("/");
              }
            }, {
              label: self.APLang.popAction.ok,
              type: 'primary',
              onClick: function(){
                // self.$router.push("/login");
                // self.loginNew();
                selt.returnToLogin()
              }
            }]
          });
        }).catch(err => {
          self.weui.loading().hide();
          self.responseError(err);
        });
      },
    }
  }
</script>
