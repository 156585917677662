<template>
  <div id="app" :class="{ pc: isPc }" :style="isPc ? '' : 'padding-top: 48px;'">
    <pc-fixed-header v-if="isPc && $store.state.isShowFix"></pc-fixed-header>
    <fixed_header v-if="!isPc"></fixed_header>
    <router-view :style="isPc ? 'min-height:' + minHeight + 'px' : ''"></router-view>
    <pc-fixed-footer v-if="isPc && $store.state.isShowFix"></pc-fixed-footer>
    <fixed_footer v-if="!isPc"></fixed_footer>
  </div>
</template>

<script>
import {isPc} from './utils/config'

// mobile
import fixed_header from "./compoments/fixedHeader.vue";
import fixed_footer from "./compoments/fixedFooter.vue";

//pc
import pcFixedHeader from './pc/common/pcFixedHeader.vue'
import pcFixedFooter from './pc/common/pcFixedFooter.vue'

export default {
  name: "app",
  data() {
    return {
      isPc: false,
      minHeight: 500
    };
  },
  components: {
    fixed_header,
    fixed_footer,
    pcFixedHeader,
    pcFixedFooter
  },
  created () {
    this.isPc = isPc
    this.minHeight = window.innerHeight - 260
  },
  mounted: function () {
  },
  methods: {
  }
};
</script>
