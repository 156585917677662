<template>
  <div class="fixed-header">
    <div class="top-bar">
      <div class="pg-back"><i class="iconfont icon-back" @click="backPage" v-show="this.$route.name !== 'home'"></i></div>
      <!-- <div class="pg-nav" v-if="PGNav" @click="showPGNav()"><i class="iconfont icon-list"></i></div> -->
      <div class="search" v-if="showSearch">
        <input class="input" v-model="searchValue" type="search" @keyup.13="gotoSearch" placeholder="请输入内容进行搜索">
        <i class="iconfont icon-search" @click="gotoSearch"></i>
      </div>
      <div v-else class="pg-title" v-html="showPGTitle"></div>
      <div class="pg-nav"></div>
    </div>
    <!-- <div class="pg-nav-content" v-if="PGNav && PGNavContent" :class="{show : PGNavShow}">
      <div class="weui-cells">
        <a class="weui-cell weui-cell_access" href="javascript:;" @click="goHome">
          <div class="weui-cell__bd">
              <p>{{Tranhome}}</p>
          </div>
          <div class="weui-cell__ft">
          </div>
        </a>
        <a class="weui-cell weui-cell_access" href="javascript:;" @click="toSearch">
          <div class="weui-cell__bd">
            <p>{{Transearch}}</p>
          </div>
          <div class="weui-cell__ft">
          </div>
        </a>
        <a class="weui-cell weui-cell_access" href="javascript:;" @click="loginUser" v-if="isLogin">
          <div class="weui-cell__bd">
            <p>{{TranLogin}}</p>
          </div>
          <div class="weui-cell__ft">
          </div>
        </a>
        <a class="weui-cell weui-cell_access" href="javascript:;" @click="logoutUser" v-else>
          <div class="weui-cell__bd">
            <p>{{TranLogout}}</p>
          </div>
          <div class="weui-cell__ft">
          </div>
        </a>
      </div>
    </div> -->
  </div>
</template>

<script>
  export default {
    name: "fixed-header",
    data() {
      return {
        Tranhome: this.APLang.home,
        Transearch: this.APLang.search,
        TranLogin: this.APLang.login,
        TranLogout: this.APLang.logout,
        // Tranabout: this.APLang.about,
        PGNav: true,
        PGNavContent: false,
        PGNavShow: false,
        isLogin: false,
        searchValue: ''
      };
    },
    mounted: function(){
      this.isLogin = sessionStorage.getItem('userToken') ? false : true
    },
    computed: {
      showPGTitle() {
        return this.$store.state.title;
      },
      showSearch() {
        return this.$store.state.isShowSearch
      }
    },
    methods: {
      showPGNav(){
        this.PGNavContent = !this.PGNavContent;
        setTimeout(() => {
          this.PGNavShow = !this.PGNavShow;
        }, 100);
      },
      goHome(){
        this.$router.push('/');
        this.isHome = true;
        this.showPGNav();
      },
      backPage(){
        this.$router.go(-1);
      },
      toSearch() {
        this.showPGNav()
        this.$router.push('/search');
      },
      loginUser() {
        this.showPGNav()
        this.$router.push('/login')
      },
      logoutUser() {
        this.showPGNav()
        let self = this;
        if(sessionStorage.getItem("userToken")) {
          self.weui.dialog({
            title: '',
            content: self.APLang.msg.logged,
            buttons: [{
              label: self.APLang.popAction.cancel,
              type: 'default',
              onClick: function () {
                //
              }
            }, {
              label: self.APLang.popAction.ok,
              type: 'primary',
              onClick: function () {
                sessionStorage.removeItem("userToken");
                self.weui.topTips(self.APLang.msg.logoutSuccess,{
                  duration: 600,
                  callback: function(){
                    window.location.reload();
                    self.$router.push("/");
                  }
                });
              }
            }]
          });
        }
      },
      // 去搜索
      gotoSearch() {
        this.$router.push('/search?q=' + this.searchValue);
      }
    }
  };
</script>

<style scoped>
.top-bar {
  padding: 5px 0;
}
.search {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
.search input {
  border-radius: 20px;
  height: 38px;
  padding-left: 40px;
  font-size: 14px;
  -webkit-appearance: none;
}
.search i {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  line-height: 38px;
  text-align: center;
  color: #666;
}
</style>
