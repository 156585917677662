var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pc-fixed-header" }, [
    _c("div", { staticClass: "pc-wrap" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: "static/images/logo-ape.png", alt: "" },
          on: { click: _vm.routerLinkToHome }
        })
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "pc-fixed-menu cl" },
        [
          _c(
            "li",
            { staticClass: "fl-l", on: { click: _vm.routerLinkToHome } },
            [_vm._v("首页")]
          ),
          _vm._v(" "),
          _vm._l(_vm.categoriesData, function(item, index) {
            return _c(
              "li",
              {
                key: item.id,
                staticClass: "fl-l",
                on: {
                  mouseover: function($event) {
                    _vm.hoverIndex = index
                  },
                  mouseout: function($event) {
                    _vm.hoverIndex = -1
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(item.name))]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "iconfont icon-menu-down",
                  class: { active: index === _vm.hoverIndex }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hoverIndex === index,
                        expression: "hoverIndex === index"
                      }
                    ],
                    staticClass: "pc-menu-box"
                  },
                  _vm._l(_vm.chilidCategoriesData[index], function(item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        on: {
                          click: function($event) {
                            return _vm.routerLinkToArticle(item.id, item.name)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: item.meta._avatar_url_cat, alt: "" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.name))])
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pc-search-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchValue,
              expression: "searchValue"
            }
          ],
          staticClass: "input",
          attrs: { type: "search", placeholder: "输入关键字搜索" },
          domProps: { value: _vm.searchValue },
          on: {
            click: _vm.getHotSearchList,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.getSearch($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchValue = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "iconfont icon-search",
          on: { click: _vm.getSearch }
        }),
        _vm._v(" "),
        _vm.isSHowHotSearch
          ? _c(
              "div",
              { staticClass: "search-tips", attrs: { id: "search-tips" } },
              [
                _c("div", { staticClass: "search-trending" }, [
                  _c("h5", { staticClass: "search-trending-header cl" }, [
                    _vm._v("\n            热门搜索\n            "),
                    _c(
                      "span",
                      { on: { click: _vm.getNextPageHotSearchList } },
                      [_vm._v("换一批")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "search-trending-tag-wrap" },
                    _vm._l(_vm.hotSearchList, function(item) {
                      return _c(
                        "li",
                        {
                          on: {
                            click: function($event) {
                              return _vm.getSearchByHot(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "search-recent" }, [
                  _c(
                    "ul",
                    { staticClass: "search-recent-item-wrap" },
                    _vm._l(_vm.historySearch, function(item, index) {
                      return _c("li", { staticClass: "search-recent-item" }, [
                        _c(
                          "div",
                          {
                            on: {
                              click: function($event) {
                                return _vm.getSearchByHot(item)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "iconfont icon-history1" }),
                            _c("span", [_vm._v(_vm._s(item))])
                          ]
                        ),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "iconfont icon-close history-close",
                          on: {
                            click: function($event) {
                              return _vm.deleteHistorySearch(item)
                            }
                          }
                        })
                      ])
                    }),
                    0
                  )
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.currentUserIsLogin
        ? _c("div", { staticClass: "pc-fixed-header-user fl-r" }, [
            _c(
              "button",
              {
                staticClass: "weui-btn weui-btn_inline weui-btn_default",
                staticStyle: { "font-size": "14px", "line-height": "1.5" },
                attrs: { type: "primary" },
                on: { click: _vm.showLogin }
              },
              [_vm._v("立即登录")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "weui-btn weui-btn_inline weui-btn_primary",
                staticStyle: { "font-size": "14px", "line-height": "1.5" },
                attrs: { type: "primary" },
                on: { click: _vm.showRegister }
              },
              [_vm._v("免费注册")]
            )
          ])
        : _c("div", { staticClass: "pc-fixed-header-user fl-r" }, [
            _c("img", {
              staticClass: "pc-header-avatar",
              attrs: { src: _vm.userInfo.avatar, alt: "" },
              on: { mouseover: _vm.imgMouseOver, mouseout: _vm.imgMouseOut }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "inline-block",
                  width: "100px",
                  height: "40px",
                  "line-height": "40px",
                  margin: "8px 15px 0",
                  "border-radius": "20px",
                  "font-size": "15px",
                  color: "#fff",
                  "text-align": "center",
                  cursor: "pointer",
                  "background-color": "#ea6f5a"
                },
                on: { click: _vm.routerLinkToWrite }
              },
              [_vm._v("写文章")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowHeaderInfo,
                    expression: "isShowHeaderInfo"
                  }
                ],
                staticClass: "pc-header-avatar-info",
                on: { mouseover: _vm.imgMouseOver, mouseout: _vm.imgMouseOut }
              },
              [
                _c("ul", [
                  _c("li", { on: { click: _vm.routerLinkToMyZone } }, [
                    _c("i", { staticClass: "iconfont icon-user2" }),
                    _vm._v(" 我的空间")
                  ]),
                  _vm._v(" "),
                  _c("li", { on: { click: _vm.routerLinkToMyWallet } }, [
                    _c("i", { staticClass: "iconfont icon-wallet" }),
                    _vm._v(" 我的钱包")
                  ]),
                  _vm._v(" "),
                  _c("li", { on: { click: _vm.routerLinkToMySetting } }, [
                    _c("i", { staticClass: "iconfont icon-settings2" }),
                    _vm._v(" 设置")
                  ]),
                  _vm._v(" "),
                  _vm.isAdmin
                    ? _c(
                        "li",
                        { on: { click: _vm.routerLinkToCategoriesSetting } },
                        [
                          _c("i", { staticClass: "iconfont icon-settings2" }),
                          _vm._v(" 目录设置")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdmin
                    ? _c("li", { on: { click: _vm.routerLinkToSiteSetting } }, [
                        _c("i", { staticClass: "iconfont icon-settings2" }),
                        _vm._v(" 站点管理")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", { on: { click: _vm.logoutUser } }, [
                    _c("i", { staticClass: "iconfont icon-exit2" }),
                    _vm._v(" 退出")
                  ])
                ])
              ]
            )
          ])
    ]),
    _vm._v(" "),
    _vm.$store.state.isShowLogin
      ? _c("div", { staticClass: "weui-dialog_confirm" }, [
          _c("div", { staticClass: "weui-mask", on: { click: _vm.hideLogin } }),
          _vm._v(" "),
          _c("div", { staticClass: "weui-dialog" }, [
            _c("div", { staticClass: "weui-dialog__hd" }, [
              _c("strong", { staticClass: "weui-dialog__title" }, [
                _vm._v("登录")
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "fl-r weui-dialog__close",
                  on: { click: _vm.hideLogin }
                },
                [_vm._v("x")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "weui-dialog__bd ta-l" }, [
              _c(
                "form",
                {
                  attrs: { id: "login" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitLogin($event)
                    }
                  }
                },
                [
                  _c("div", {}, [
                    _c("div", { staticClass: "cell" }, [
                      _c("div", { staticClass: "cell-head" }, [
                        _c("label", { staticClass: "weui-label" }, [
                          _vm._v(_vm._s(_vm.Tran_user))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userData.username,
                            expression: "userData.username"
                          }
                        ],
                        staticClass: "cell-input",
                        attrs: {
                          type: "text",
                          placeholder: _vm.Tran_username,
                          autocomplete: "username"
                        },
                        domProps: { value: _vm.userData.username },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userData,
                              "username",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cell" }, [
                      _c("div", { staticClass: "cell-head" }, [
                        _c("label", { staticClass: "weui-label" }, [
                          _vm._v(_vm._s(_vm.Tran_pwd))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userData.password,
                            expression: "userData.password"
                          }
                        ],
                        staticClass: "cell-input",
                        attrs: {
                          type: "password",
                          placeholder: _vm.Tran_password,
                          autocomplete: "current-password"
                        },
                        domProps: { value: _vm.userData.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userData,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "weui-cell weui-cell_switch" }, [
                      _c("div", { staticClass: "weui-cell__bd" }, [
                        _vm._v(_vm._s(_vm.Tran_autoLogin))
                      ]),
                      _vm._v(" "),
                      _vm._m(0)
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "weui-cells__tips",
                        on: { click: _vm.showRegister }
                      },
                      [_vm._v(_vm._s(_vm.Tran_noneAccount))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "weui-cells__tips",
                        on: { click: _vm.showForget }
                      },
                      [_vm._v("忘记密码？")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "weui-btn-area" }, [
                    _c(
                      "button",
                      {
                        staticClass: "weui-btn weui-btn_primary",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.Tran_login))]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "more-login" }, [
                _c("h6", [_vm._v("社交帐号登录")]),
                _vm._v(" "),
                _c("div", [
                  _c("i", {
                    staticClass: "iconfont icon-wechat",
                    on: { click: _vm.toWechatLogin }
                  })
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.state.isShowRegister
      ? _c("div", { staticClass: "weui-dialog_confirm" }, [
          _c("div", {
            staticClass: "weui-mask",
            on: { click: _vm.hideRegister }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "weui-dialog" }, [
            _c("div", { staticClass: "weui-dialog__hd" }, [
              _c("strong", { staticClass: "weui-dialog__title" }, [
                _vm._v("注册")
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "fl-r weui-dialog__close",
                  on: { click: _vm.hideRegister }
                },
                [_vm._v("x")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "weui-dialog__bd ta-l" }, [
              _c(
                "form",
                {
                  attrs: { id: "register" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitRegister($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "weui-cells weui-cells_form" }, [
                    _c("div", { staticClass: "weui-cell" }, [
                      _c("div", { staticClass: "weui-cell__hd" }, [
                        _c("label", { staticClass: "weui-label" }, [
                          _vm._v(_vm._s(_vm.Tran_user))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weui-cell__bd" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.registerData.username,
                              expression: "registerData.username"
                            }
                          ],
                          staticClass: "weui-input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.Tran_username
                          },
                          domProps: { value: _vm.registerData.username },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.registerData,
                                "username",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "weui-cell" }, [
                      _c("div", { staticClass: "weui-cell__hd" }, [
                        _c("label", { staticClass: "weui-label" }, [
                          _vm._v(_vm._s(_vm.Tran_mail))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weui-cell__bd" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.registerData.email,
                              expression: "registerData.email"
                            }
                          ],
                          staticClass: "weui-input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.Tran_email,
                            autocomplete: "email"
                          },
                          domProps: { value: _vm.registerData.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.registerData,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "weui-cells__tips",
                        on: { click: _vm.returnToLogin }
                      },
                      [_vm._v(_vm._s(_vm.Tran_haveAccount))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "weui-btn-area" }, [
                    _c(
                      "button",
                      {
                        staticClass: "weui-btn weui-btn_primary",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.Tran_register))]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "more-login" }, [
                _c("h6", [_vm._v("社交帐号登录")]),
                _vm._v(" "),
                _c("div", [
                  _c("i", {
                    staticClass: "iconfont icon-wechat",
                    on: { click: _vm.toWechatLogin }
                  })
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.state.isShowForget
      ? _c("div", { staticClass: "weui-dialog_confirm" }, [
          _c("div", {
            staticClass: "weui-mask",
            on: { click: _vm.hideForget }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "weui-dialog" }, [
            _c("div", { staticClass: "weui-dialog__hd" }, [
              _c("strong", { staticClass: "weui-dialog__title" }, [
                _vm._v("忘记密码")
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "fl-r weui-dialog__close",
                  on: { click: _vm.hideForget }
                },
                [_vm._v("x")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "weui-dialog__bd ta-l" }, [
              _c(
                "form",
                {
                  attrs: { id: "forget" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitLostPassword($event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "weui-cells weui-cells_form" }, [
                    _c("div", { staticClass: "weui-cell" }, [
                      _c("div", { staticClass: "weui-cell__hd" }, [
                        _c("label", { staticClass: "weui-label" }, [
                          _vm._v(_vm._s(_vm.Tran_mail))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "weui-cell__bd" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.forgetData.email,
                              expression: "forgetData.email"
                            }
                          ],
                          staticClass: "weui-input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.Tran_email,
                            autocomplete: "email"
                          },
                          domProps: { value: _vm.forgetData.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.forgetData,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "weui-cells__tips",
                        on: { click: _vm.returnToLogin }
                      },
                      [_vm._v("返回登录")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(1)
                ]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "weui-cell__ft" }, [
      _c("input", { staticClass: "weui-switch", attrs: { type: "checkbox" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "weui-btn-area" }, [
      _c(
        "button",
        { staticClass: "weui-btn weui-btn_primary", attrs: { type: "submit" } },
        [_vm._v("找回密码")]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-234083dd", { render: render, staticRenderFns: staticRenderFns })
  }
}