<template>
<div class="weui-footer footer">
  <p class="weui-footer__links">
    <span class="weui-footer__link">{{copy}}</span>
    <span class="weui-footer__link" @click="routerLinkToMyAbout">关于我们</span>
  </p>
  <p class="weui-footer__text">Copyright &copy; {{website_record}} &nbsp; {{ website_copyright }}</p>
</div>
</template>

<script>
import { apiUrl } from "../../utils/api.js";

export default {
  data() {
    return {
      copy: '',
      dateYear: '',
      website_record: '',
      website_copyright: ''
    };
  },
  mounted: function () {
    this.getYear();
    this.siteConfig(data=>{
      this.copy = data.name;
      document.title = data.name
    });
    this.getWebsiteInfo()
  },
  methods: {
    getYear(){
      let newYear = new Date();
      let curYear = newYear.getFullYear();
      this.dateYear = curYear;
    },
    getWebsiteInfo () {
      apiUrl.get('getWebsiteInfo').then(res => {
        this.website_record = res.data.website_record
        this.website_copyright = res.data.website_copyright
      })
    },
    routerLinkToMyAbout() {
      // this.$router.push('/about')
      window.open('http://www.toceancn.com/company/index.jhtml')
    },
  }
};
</script>

<style scoped>
.footer {
  margin-top: 90px;
  padding: 20px 0;
  border-top: 1px solid #e4e4e4;
}
span {
  cursor: pointer;
}
span:hover {
  color: #009a61;
}
</style>
