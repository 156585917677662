var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "weui-footer footer" }, [
    _c("p", { staticClass: "weui-footer__links" }, [
      _c("span", { staticClass: "weui-footer__link" }, [
        _vm._v(_vm._s(_vm.copy))
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "weui-footer__link",
          on: { click: _vm.routerLinkToMyAbout }
        },
        [_vm._v("关于我们")]
      )
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "weui-footer__text" }, [
      _vm._v(
        "Copyright © " +
          _vm._s(_vm.website_record) +
          "   " +
          _vm._s(_vm.website_copyright)
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-020e1feb", { render: render, staticRenderFns: staticRenderFns })
  }
}