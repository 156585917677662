const vfilter = {
  publishTime: function (value) {
    if (value) {
      let nowDate = new Date().getTime()
      let oldDate = new Date(value)
      let diff = nowDate - oldDate.getTime()
      if (diff > 86400000) {
        if (diff > 259200000) { // 大于三天直接显示日期
          return value.split('T')[0]
        } else {
          return (diff / 86400000).toFixed(0) + '天前'
        }
      } else {
        if (diff > 3600000) {
          return (diff / 3600000).toFixed(0) + '小时前'
        } else {
          if (diff <= 60000) {
            return '刚刚'
          } else {
            return (diff / 60000).toFixed(0) + '分前'
          }
        }
      }
    } else {
      return value
    }
  },
  dateTime: function (value) {
    return value.split(' ')[0]
  }
}

export default vfilter