var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fixed-header" }, [
    _c("div", { staticClass: "top-bar" }, [
      _c("div", { staticClass: "pg-back" }, [
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.$route.name !== "home",
              expression: "this.$route.name !== 'home'"
            }
          ],
          staticClass: "iconfont icon-back",
          on: { click: _vm.backPage }
        })
      ]),
      _vm._v(" "),
      _vm.showSearch
        ? _c("div", { staticClass: "search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchValue,
                  expression: "searchValue"
                }
              ],
              staticClass: "input",
              attrs: { type: "search", placeholder: "请输入内容进行搜索" },
              domProps: { value: _vm.searchValue },
              on: {
                keyup: function($event) {
                  if (!$event.type.indexOf("key") && $event.keyCode !== 13) {
                    return null
                  }
                  return _vm.gotoSearch($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchValue = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("i", {
              staticClass: "iconfont icon-search",
              on: { click: _vm.gotoSearch }
            })
          ])
        : _c("div", {
            staticClass: "pg-title",
            domProps: { innerHTML: _vm._s(_vm.showPGTitle) }
          }),
      _vm._v(" "),
      _c("div", { staticClass: "pg-nav" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7eff6740", { render: render, staticRenderFns: staticRenderFns })
  }
}