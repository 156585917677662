var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { pc: _vm.isPc },
      style: _vm.isPc ? "" : "padding-top: 48px;",
      attrs: { id: "app" }
    },
    [
      _vm.isPc && _vm.$store.state.isShowFix ? _c("pc-fixed-header") : _vm._e(),
      _vm._v(" "),
      !_vm.isPc ? _c("fixed_header") : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        style: _vm.isPc ? "min-height:" + _vm.minHeight + "px" : ""
      }),
      _vm._v(" "),
      _vm.isPc && _vm.$store.state.isShowFix ? _c("pc-fixed-footer") : _vm._e(),
      _vm._v(" "),
      !_vm.isPc ? _c("fixed_footer") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ef48958", { render: render, staticRenderFns: staticRenderFns })
  }
}