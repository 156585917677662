import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    title: '',
    footer: false,
    userToken: '',
    categoriesData: [],
    userId: '',
    isShowFix: true,
    isShowLogin: false,
    isShowRegister: false,
    isShowForget: false,
    onesArticleCount: 0,
    writeMenuActiveIndex: 0,
    isShowSearch: true
  },
  mutations: {
    newTitle(state,msg) {
      state.title = msg;
    },
    showFooter(state,msg) {
      state.footer = msg;
    },
    setUserToken(state,token) {
      state.userToken = token;
    },
    setCategoriesData(state, data) {
      state.categoriesData = data
    },
    setUserId (state, msg) {
      state.userId = msg
    },
    setIsShowFix (state, msg) {
      state.isShowFix = msg
    },
    setIsShowLogin (state, msg) {
      state.isShowLogin = msg
    },
    setIsShowRegister (state, msg) {
      state.isShowRegister = msg
    },
    setIsShowForget (state, msg) {
      state.isShowForget = msg
    },
    setOnesArticleCount (state, msg) {
      state.onesArticleCount = msg
    },
    setWriteMenuActiveIndex (state, msg) {
      state.writeMenuActiveIndex = msg
    },
    setIsShowSearch (state, msg) {
      state.isShowSearch = msg
    }
  },
  actions: {
    setCategoriesData(context, data) {
      context.commit('setCategoriesData', data)
    },
    setUserId (context, data) {
      context.commit('setUserId', data)
    },
    setIsShowFix (context, data) {
      context.commit('setIsShowFix', data)
    },
    setIsShowLogin (context, data) {
      context.commit('setIsShowLogin', data)
    },
    setIsShowRegister (context, data) {
      context.commit('setIsShowRegister', data)
    },
    setIsShowForget (context, data) {
      context.commit('setIsShowForget', data)
    },
    setOnesArticleCount(context, data) {
      context.commit('setOnesArticleCount', data)
    },
    setWriteMenuActiveIndex (context, data) {
      context.commit('setWriteMenuActiveIndex', data)
    },
    setIsShowSearch (context, data) {
      context.commit('setIsShowSearch', data)
    }
  }

});

export default store;